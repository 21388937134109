<script setup lang="ts">
import InlinePage from './content/InlinePage.vue'
import { clamp } from './util'

const page = ref<string | null>(null)
const fragment = ref<string | null>(null)
const preview = ref(null as HTMLElement | null)

const left = ref(0)
const top = ref(0)
const width = ref(0)

onBodyEvent('setPreview', (ev) => {
  update(ev.detail, ev.target)
})

type EventDetail = {
  link: string
  frag?: string
}

const article = computed(() => document.querySelector('article'))

const timeoutHandle = ref()
const update = (detail: EventDetail, target: HTMLElement) => {
  const { link, frag } = detail
  const show = link !== null
  page.value = link
  fragment.value = frag ?? null

  if (show) {
    timeoutHandle.value = setTimeout(() => preview.value?.showPopover(), 200)
    const rect = target.getBoundingClientRect()
    const limits = article.value?.getBoundingClientRect()
    const targetWidth = 450 // any smaller and spell tables overflow
    const gutter = 4
    width.value = Math.min((limits?.width ?? targetWidth) + 2 * gutter, targetWidth)

    const xAdjust = (rect.width - width.value) / 2
    const yAdjust = gutter

    left.value = clamp(
      Math.floor(rect.left) + document.documentElement.scrollLeft + xAdjust,
      (limits?.left ?? 0) - gutter,
      (limits?.right ?? 1000) - width.value + gutter
    )
    top.value = Math.floor(rect.bottom) + document.documentElement.scrollTop + yAdjust
  } else {
    clearTimeout(timeoutHandle.value)
    preview.value?.hidePopover()
  }
}
</script>

<template>
  <div
    ref="preview"
    popover="manual"
    class="card preview"
    :style="{ top: `${top}px`, left: `${left}px`, width: `${width}px` }"
  >
    <InlinePage
      v-if="page"
      :key="page"
      :page="page"
      :level="3"
      :overridefragment="fragment ?? undefined"
      class="preview-tooltip"
    />
  </div>
</template>

<style scoped lang="postcss">
.preview {
  position: absolute;
  margin: 0;

  background: var(--background);
  border: 2px solid var(--primary);
  border-radius: 1em;
  padding: 1em;
  padding-top: 0.75em;
  color: var(--text);
}
</style>

<style lang="postcss">
.preview {
  h3 {
    margin-top: 0;
  }
  .nuxt-content {
    *:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
